<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">対象廃棄物種類設定</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div class="c-modal__body__originalList">
          <div class="c-radio">
            <input
                id="industrial-waste"
                type="radio"
                name="industrial-waste"
                :value="true"
                v-model="isIndustrialWaste"
                @change="selectedItemType = []"
            />
            <label class="c-radio__label" for="industrial-waste">
              <span class="c-radio__box"></span>
              産業廃棄物
            </label>
          </div>
          <div class="c-radio">
            <input
                id="special-waste"
                type="radio"
                name="special-waste"
                :value="false"
                v-model="isIndustrialWaste"
                @change="selectedItemType = []"
            />
            <label class="c-radio__label" for="special-waste">
              <span class="c-radio__box"></span>
              特別管理産業廃棄物
            </label>
          </div>
        </div>
      </div>
      <div class="c-modal__body">
        <div>
          <div v-if="isIndustrialWaste" class="c-modal__body__originalList">
            <div
                class="c-checkbox"
                v-for="normalItem in jwnetItemLicences.filter(item => !item.isSpecial)"
                :key="'normal-item-' + normalItem.id"
            >
              <input
                  :id="'normal-item-' + normalItem.id"
                  type="checkbox"
                  :value="normalItem"
                  v-model="selectedItemType"
              />
              <label class="c-checkbox__label" :for="'normal-item-' + normalItem.id">
                <span class="c-checkbox__box"></span>
                {{ normalItem.name }}
              </label>
            </div>
          </div>
          <div v-else class="c-modal__body__originalList">
            <div
                class="c-checkbox"
                v-for="specialItem in jwnetItemLicences.filter(item => item.isSpecial)"
                :key="'special-item-' + specialItem.id"
            >
              <input
                  :id="'special-item-' + specialItem.id"
                  type="checkbox"
                  :value="specialItem"
                  v-model="selectedItemType"
              />
              <label class="c-checkbox__label" :for="'special-item-' + specialItem.id">
                <span class="c-checkbox__box"></span>
                {{ specialItem.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn secondary small" @click="closeModalHandler">キャンセル</div>
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixin/common";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    jwnetItemLicences: {
      type: Array,
    },
  },
  mixins: [common],
  data() {
    return {
      selectedItemType: [],
      isIndustrialWaste: true,
    };
  },
  methods: {
    setSelectedJwnetItems(items) {
      const filteredJwnetItems = this.jwnetItemLicences.filter((jwnetItem) => {
        return items.map ((item) => item.id).includes(jwnetItem.id);
      });
      this.selectedItemType = filteredJwnetItems;
    },
    closeModalHandler() {
      this.$emit("update:isShow", false);
      this.selectedItemType = [];
    },
    clickSetHandler() {
      this.$emit("update:isShow", false);
      this.$emit("setSelectedJwnetItems", this.selectedItemType);
      this.selectedItemType = [];
    },
  },
};
</script>
